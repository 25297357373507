<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách thông tin về cung lao động</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :selectOptions="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        ></vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} thông tin về cung lao động</p>

        <!-- {{ dataInvalid.length }} -->
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách thông tin về cung lao động không hợp lệ</h4>

        </div>
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- ======================Họ và tên ===================================-->
            <span
              v-if="props.column.field == 'fullName' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'FullName'||!props.row.fullName,dataErr:!props.row.fullName,}"
            >
              {{ props.row.fullName }}
            </span>
            <span v-if="props.column.field == 'fullName' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'FullName'"
                v-model="props.row.fullName"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.fullName, 'fullName', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'FullName'"
              >{{ props.row.fullName }}</span>
            </span>

            <!-- ====================== Ngày sinh ===================================-->
            <span
              v-if="props.column.field == 'birthDay' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'BirthDay'||!props.row.birthDay,dataErr:!props.row.birthDay,}"
            >
              {{ props.row.birthDay }}
            </span>
            <span v-if="props.column.field == 'birthDay' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'BirthDay'"
                v-model="props.row.birthDay"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.birthDay, 'birthDay', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'BirthDay'"
              >{{ props.row.birthDay }}</span>
            </span>

            <!-- ====================== Giới tính===================================-->
            <span
              v-if="props.column.field == 'gender' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Gender'||!props.row.gender,dataErr:!props.row.gender,}"
            >
              {{ props.row.gender }}
            </span>
            <span v-if="props.column.field == 'gender' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Gender'"
                v-model="props.row.gender"
                :reduce="e => e"
                :options="genderList"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.gender, 'gender', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Gender'"
              >{{ props.row.gender }}</span>
            </span>

            <!-- =========================Tỉnh/Thành phố============================= -->
            <span
              v-if="props.column.field == 'province' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Province'||!props.row.province,dataErr:!props.row.province,}"
            >
              {{ props.row.province }} {{ props.row.isDisabledDis }}
            </span>
            <span v-if="props.column.field == 'province' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Province'"
                v-model="props.row.province"
                :reduce="title => title.name"
                label="name"
                :options="provinceList || []"
                @input="changeVselectCellvalue(props.row.province, 'province', props.row, 'isDisabledDis')"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Province'"
              >{{ props.row.province }}</span>
            </span>

            <!-- =========================Tỉnh/Thành phố============================= -->

            <!-- =========================Huyện/Quận============================= -->
            <span
              v-if="props.column.field == 'district' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'District'||!props.row.district,dataErr:!props.row.district,}"
            >
              {{ props.row.district }}
            </span>
            <span v-if="props.column.field == 'district' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Province'"
                v-model="props.row.district"
                :reduce="item => item.name"
                label="name"
                :disabled="props.row.isDisabledDis"
                :options="districtList || []"
                @input="changeVselectCellvalue(props.row.district, 'district', props.row, 'isDisabledWard' )"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Province'"
              >{{ props.row.district }}</span>
            </span>

            <!-- =========================Huyện/Quận============================= -->

            <!-- =========================Xã/Phường============================= -->
            <span
              v-if="props.column.field == 'ward' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Ward'||!props.row.ward,dataErr:!props.row.ward,}"
            >
              {{ props.row.ward }}
            </span>
            <span v-if="props.column.field == 'ward' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Province'"
                v-model="props.row.ward"
                :reduce="item => item.name"
                label="name"
                :disabled="props.row.isDisabledWard"
                :options="wardList || []"
                @input="changeVselectCellvalue(props.row.ward, 'ward', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Province'"
              >{{ props.row.ward }}</span>
            </span>
            <!-- =========================Xã/Phường============================= -->

            <!-- ====================== Đối tượng ưu tiên ===================================-->
            <span
              v-if="props.column.field == 'priorityWorker' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'PriorityWorker'||!props.row.priorityWorker,dataErr:!props.row.priorityWorker,}"
            >
              {{ props.row.priorityWorker }}
            </span>
            <span v-if="props.column.field == 'priorityWorker' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'PriorityWorker'"
                v-model="props.row.priorityWorker"
                :reduce="data => data.name"
                :label="'name'"
                :options="priorityWorker || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.priorityWorker, 'priorityWorker', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'PriorityWorker'"
              >{{ props.row.priorityWorker }}</span>
            </span>

            <!-- ====================== Trình độ giáo dục phổ thông ===================================-->
            <span
              v-if="props.column.field == 'educationLevelWorker' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'EducationLevelWorker'||!props.row.educationLevelWorker,dataErr:!props.row.educationLevelWorker,}"
            >
              {{ props.row.educationLevelWorker }}
            </span>
            <span v-if="props.column.field == 'educationLevelWorker' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'EducationLevelWorker'"
                v-model="props.row.educationLevelWorker"
                :reduce="data => data.name"
                :label="'name'"
                :options="educationLevelWorker || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.educationLevelWorker, 'educationLevelWorker', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'EducationLevelWorker'"
              >{{ props.row.educationLevelWorker }}</span>
            </span>

            <!-- ====================== Trình độ chuyên môn kỹ thuật ===================================-->
            <span
              v-if="props.column.field == 'qualificationLevel' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'QualificationLevel'||!props.row.qualificationLevel,dataErr:!props.row.qualificationLevel,}"
            >
              {{ props.row.qualificationLevel }}
            </span>
            <span v-if="props.column.field == 'qualificationLevel' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'QualificationLevel'"
                v-model="props.row.qualificationLevel"
                :reduce="data => data.name"
                :label="'name'"
                :options="qualificationLevel || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.qualificationLevel, 'qualificationLevel', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'QualificationLevel'"
              >{{ props.row.qualificationLevel }}</span>
            </span>

            <!-- ====================== Tình trạng tham gia hoạt động kinh tế ===================================-->
            <span
              v-if="props.column.field == 'statusCollectWorker' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'StatusCollectWorker'||!props.row.statusCollectWorker,dataErr:!props.row.statusCollectWorker,}"
            >
              {{ props.row.statusCollectWorker }}
            </span>
            <span v-if="props.column.field == 'statusCollectWorker' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'StatusCollectWorker'"
                v-model="props.row.statusCollectWorker"
                :reduce="data => data.name"
                :label="'name'"
                :options="statusCollectWorker || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.statusCollectWorker, 'statusCollectWorker', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'StatusCollectWorker'"
              >{{ props.row.statusCollectWorker }}</span>
            </span>

            <!-- ====================== Vị thế việc làm ===================================-->
            <span
              v-if="props.column.field == 'jobPosition' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'JobPosition'||!props.row.jobPosition,dataErr:!props.row.jobPosition,}"
            >
              {{ props.row.jobPosition }}
            </span>
            <span v-if="props.column.field == 'jobPosition' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'JobPosition'"
                v-model="props.row.jobPosition"
                :reduce="data => data.name"
                :label="'name'"
                :options="jobPosition || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.jobPosition, 'jobPosition', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'JobPosition'"
              >{{ props.row.jobPosition }}</span>
            </span>

            <!-- ====================== Trạng thái thất nghiệp ===================================-->
            <span
              v-if="props.column.field == 'unemploymentStatus' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'UnemploymentStatus'||!props.row.unemploymentStatus,dataErr:!props.row.unemploymentStatus,}"
            >
              {{ props.row.unemploymentStatus }}
            </span>
            <span v-if="props.column.field == 'unemploymentStatus' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'UnemploymentStatus'"
                v-model="props.row.unemploymentStatus"
                :reduce="data => data.name"
                :label="'name'"
                :options="unemploymentStatus || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.unemploymentStatus, 'unemploymentStatus', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'UnemploymentStatus'"
              >{{ props.row.unemploymentStatus }}</span>
            </span>

            <!-- ====================== Thời gian thất nghiệp ===================================-->
            <span
              v-if="props.column.field == 'unemploymentTime' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'UnemploymentTime'||!props.row.unemploymentTime,dataErr:!props.row.unemploymentTime,}"
            >
              {{ props.row.unemploymentTime }}
            </span>
            <span v-if="props.column.field == 'unemploymentTime' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'UnemploymentTime'"
                v-model="props.row.unemploymentTime"
                :reduce="data => data.name"
                :label="'name'"
                :options="unemploymentTime || []"
                placeholder="Giới tính"
                @input="changeCellvalue(props.row.unemploymentTime, 'unemploymentTime', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'UnemploymentTime'"
              >{{ props.row.unemploymentTime }}</span>
            </span>

            <!-- ====================== Ngày thu thập ===================================-->
            <span
              v-if="props.column.field == 'dateCollect' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'DateCollect'||!props.row.dateCollect,dataErr:!props.row.dateCollect,}"
            >
              {{ props.row.dateCollect }}
            </span>
            <span v-if="props.column.field == 'dateCollect' && editvalueErr === true ">
              <date-time-picker
                v-if="props.row.messErr[0].location === 'DateCollect'"
                v-model="props.row.dateCollect"
                :disabledInput="true"
                :disabled="disableProp"
                @input="changeCellvalue(props.row.dateCollect, 'dateCollect', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'DateCollect'"
              >{{ props.row.dateCollect }}</span>
            </span>

            <span
              v-if="props.column.field == 'identityCardNumber'"
            >
              {{ props.row.identityCardNumber }}
            </span>
            <span
              v-if="props.column.field == 'permanentAddress'"
            >
              {{ props.row.permanentAddress }}
            </span>
            <span
              v-if="props.column.field == 'address'"
            >
              {{ props.row.address }}
            </span>
            <span
              v-if="props.column.field == 'specializedTraining'"
            >
              {{ props.row.specializedTraining }}
            </span>
            <span
              v-if="props.column.field == 'noneWorkerReason'"
            >
              {{ props.row.noneWorkerReason }}
            </span>
            <span
              v-if="props.column.field == 'job'"
            >
              {{ props.row.job }}
            </span>
            <span
              v-if="props.column.field == 'workPlace'"
            >
              {{ props.row.workPlace }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} thông tin về cung lao động</p>
      </div>
      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm thông tin về cung lao động
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import store from '../../store'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import ConstantsApi from '../../constants/ConstantsApi'
import enumSelect from '../EnumSelected'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BButton,
    DateTimePicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      disableProp: false,
      dataInvalid: [],
      dataNoInvalid: [],
      columns: [
        {
          label: 'HỌ VÀ TÊN',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Số CMND/CCCD',
          field: 'identityCardNumber',
          sortable: false,
        },
        {
          label: 'Tỉnh/Thành phố',
          field: 'province',
          sortable: false,
        },
        {
          label: 'Quận/Huyện',
          field: 'district',
          sortable: false,
        },
        {
          label: 'Phường/Xã',
          field: 'ward',
          sortable: false,
        },
        {
          label: 'Nơi đăng ký thường trú',
          field: 'permanentAddress',
          sortable: false,
        },
        {
          label: 'Nơi ở hiện tại',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Đối tượng ưu tiên',
          field: 'priorityWorker',
          sortable: false,
        },
        {
          label: 'Dân tộc thiểu số (Nếu có)',
          field: 'ethnic',
          sortable: false,
        },
        {
          label: 'Trình độ giáo dục phổ thông',
          field: 'educationLevelWorker',
          sortable: false,
        },
        {
          label: 'Trình độ chuyên môn kỹ thuật',
          field: 'qualificationLevel',
          sortable: false,
        },
        {
          label: 'Chuyên ngành đào tạo',
          field: 'specializedTraining',
          sortable: false,
        },
        {
          label: 'Tình trạng tham gia hoạt động kinh tế',
          field: 'statusCollectWorker',
          sortable: false,
        },
        {
          label: 'Lý do không tham gia hoạt động kinh tế (nếu có)',
          field: 'noneWorkerReason',
          sortable: false,
        },
        {
          label: 'Vị thế việc làm',
          field: 'jobPosition',
          sortable: false,
        },
        {
          label: 'Công việc cụ thể đang làm',
          field: 'job',
          sortable: false,
        },
        {
          label: 'Nơi làm việc',
          field: 'workPlace',
          sortable: false,
        },
        {
          label: 'Trạng thái thất nghiệp',
          field: 'unemploymentStatus',
          sortable: false,
        },
        {
          label: 'Thời gian thất nghiệp',
          field: 'unemploymentTime',
          sortable: false,
        },
        {
          label: 'Ngày thu thập',
          field: 'dateCollect',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'HỌ VÀ TÊN',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Số CMND/CCCD',
          field: 'identityCardNumber',
          sortable: false,
        },
        {
          label: 'Tỉnh/Thành phố',
          field: 'province',
          sortable: false,
        },
        {
          label: 'Quận/Huyện',
          field: 'district',
          sortable: false,
        },
        {
          label: 'Phường/Xã',
          field: 'ward',
          sortable: false,
        },
        {
          label: 'Nơi đăng ký thường trú',
          field: 'permanentAddress',
          sortable: false,
        },
        {
          label: 'Nơi ở hiện tại',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Đối tượng ưu tiên',
          field: 'priorityWorker',
          sortable: false,
        },
        {
          label: 'Dân tộc thiểu số (Nếu có)',
          field: 'ethnic',
          sortable: false,
        },
        {
          label: 'Trình độ giáo dục phổ thông',
          field: 'educationLevelWorker',
          sortable: false,
        },
        {
          label: 'Trình độ chuyên môn kỹ thuật',
          field: 'qualificationLevel',
          sortable: false,
        },
        {
          label: 'Chuyên ngành đào tạo',
          field: 'specializedTraining',
          sortable: false,
        },
        {
          label: 'Tình trạng tham gia hoạt động kinh tế',
          field: 'statusCollectWorker',
          sortable: false,
        },
        {
          label: 'Lý do không tham gia hoạt động kinh tế (nếu có)',
          field: 'noneWorkerReason',
          sortable: false,
        },
        {
          label: 'Vị thế việc làm',
          field: 'jobPosition',
          sortable: false,
        },
        {
          label: 'Công việc cụ thể đang làm',
          field: 'job',
          sortable: false,
        },
        {
          label: 'Nơi làm việc',
          field: 'workPlace',
          sortable: false,
        },
        {
          label: 'Trạng thái thất nghiệp',
          field: 'unemploymentStatus',
          sortable: false,
        },
        {
          label: 'Thời gian thất nghiệp',
          field: 'unemploymentTime',
          sortable: false,
        },
        {
          label: 'Ngày thu thập',
          field: 'dateCollect',
          sortable: false,
        },
      ],
      provinceList: [],
      districtList: [],
      wardList: [],
      comboboxCounselor: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      genderList: [
        'Nam',
        'Nữ',
      ],
      idProvince: 0,
      idDistrict: 0,
      isDisabledDis: '',
      isDisabledWard: '',
      index: '',
      priorityWorker: enumSelect.priorityWorker,
      educationLevelWorker: enumSelect.educationLevelWorker,
      qualificationLevel: enumSelect.qualificationLevel,
      statusCollectWorker: enumSelect.statusCollectWorker,
      noneWorkerReason: enumSelect.noneWorkerReason,
      jobPosition: enumSelect.jobPosition,
      unemploymentStatus: enumSelect.unemploymentStatus,
      unemploymentTime: enumSelect.unemploymentTime,
    }
  },
  computed: {
    ...mapGetters('collectInforWorker', ['validWork', 'validNoWork', 'dataTooltip']),
  },
  created() {
    const array = []
    this.dataInvalid = this.validWork
    this.validNoWork.forEach(e => {
      array.push(e = {
        ...e,
        isDisabledDis: true,
        isDisabledWard: true,
      })
    })
    this.dataNoInvalid = array
    this.checkErrorUser(this.dataTooltip)
    this.fetchComboboProvince()
  },
  methods: {
    ...mapActions('collectInforWorker', ['getApiExcel']),
    // Tỉnh thành phố
    async fetchComboboProvince() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_CITY)
      this.provinceList = data
    },

    // Quận huyện
    async fetchComboboxDistrict(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_DISTRICT}${a}`)
      this.districtList = data
    },

    // Xã phường
    async fetchComboboxWard(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_WARD}${a}`)
      this.wardList = data
    },

    changeVselectCellvalue(event, field, rowData, isDisable) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
      // Kiểm tra tới cột tỉnh thành phố
      if (field === 'province') {
        // Kiểm tra cột tỉnh thành phố có null hay không
        if (this.dataNoInvalid[rowData.originalIndex][field]) {
          this.dataNoInvalid[rowData.originalIndex][isDisable] = false
          this.isDisabledDis = isDisable
          const array = this.provinceList.filter(e => e.name === event)
          const a = array[0]
          this.idProvince = a.id
          this.fetchComboboxDistrict(this.idProvince)
        } else {
          const district = 'district'
          const isDisabledWard = 'isDisabledWard'
          const ward = 'ward'
          this.dataNoInvalid[rowData.originalIndex][ward] = null
          this.dataNoInvalid[rowData.originalIndex][district] = null
          this.dataNoInvalid[rowData.originalIndex][isDisable] = true
          this.dataNoInvalid[rowData.originalIndex][isDisabledWard] = true
        }
      }

      // Kiểm tra tới cột quận huyện
      if (field === 'district') {
        // Kiểm tra cột quận huyện null hay không
        if (this.dataNoInvalid[rowData.originalIndex][field]) {
          this.dataNoInvalid[rowData.originalIndex][isDisable] = false
          this.isDisabledWard = isDisable
          const array = this.districtList.filter(e => e.name === event)
          const id = array[0]
          this.idDistrict = id.id
          this.fetchComboboxWard(this.idDistrict)
        } else {
          const ward = 'ward'
          this.dataNoInvalid[rowData.originalIndex][ward] = null
          this.dataNoInvalid[rowData.originalIndex][isDisable] = true
        }
      }
    },
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'collect-information-worker' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      if (this.dataInvalid.length > 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        this.getApiExcel(model).then(res => {
          if (res.status === 200) {
            this.$root.$bvToast.toast('Thêm thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.dataInvalid = []
            if (this.dataNoInvalid.length === 0) {
              this.$router.push({ name: 'collect-information-worker' })
            }
          } else {
            this.$root.$bvToast.toast('Thêm không thành công', {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      } else {
        this.$root.$bvToast.toast('Thêm không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
